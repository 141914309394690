<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					MD-Staff Reports
				</h2>
			</div>
		</div>

		<div class="mb-2">
			<div class="border-b border-gray-200">
				<nav class="-mb-px flex space-x-8" aria-label="Tabs">
					<button
						v-for="(item, index) in navigation"
						:key="item.name"
						:class="[
							item.current
								? 'border-blue-500 text-blue-600'
								: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
							'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
						]"
						:aria-current="item.current ? 'page' : undefined"
						@click.prevent="changeTab(index)"
					>
						<font-awesome-icon
							v-if="iconType(item) === 'fa'"
							:icon="item.faIcon"
							:class="[
								item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
								'-ml-0.5 mr-2 h-5 w-5',
							]"
							aria-hidden="true"
						/>

						<component
							:is="item.icon"
							v-if="iconType(item) === 'hero'"
							:class="[
								item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
								'-ml-0.5 mr-2 h-5 w-5',
							]"
							aria-hidden="true"
						/>
						<span class="truncate">
							{{ item.name }}
						</span>
					</button>
				</nav>
			</div>
		</div>

		<div class="sm:px-6 lg:px-0">
			<ReportsMdStaffProviderMissingImages v-if="currentTab == 0" />
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PhotographIcon } from '@heroicons/vue/outline'

export default {
	components: {
		'font-awesome-icon': FontAwesomeIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			currentTab: 0,
			navigation: [
				{
					name: 'Provider Missing Images',
					icon: PhotographIcon,
					current: true,
				},
			],
		}
	},
	mounted() {},
	methods: {
		changeTab(index) {
			this.navigation.forEach(element => {
				element.current = false
			})

			this.navigation[index].current = true
			this.currentTab = index
		},

		iconType(item) {
			if (typeof item.icon !== 'undefined') {
				return 'hero'
			} else if (typeof item.faIcon !== 'undefined') {
				return 'fa'
			}
		},
	},
}
</script>
